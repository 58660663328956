<script lang='ts'>
  import { createEventDispatcher } from 'svelte'
  import { fly } from 'svelte/transition'

  import { reactionEmojis } from '@/helpers/emojiHelpers'
  import { clickOutside } from '@/helpers/mixHelpers'

  import IconSmiley from "@/components/icons/phosphor/IconSmiley.svelte";
  import FakeButton from '@/components/ui/FakeButton.svelte'

  let shown = false

  const dispatch = createEventDispatcher()

  function selectEmoji (emoji: string) {
    dispatch('select', emoji)
    shown = false
  }
</script>

<div class='reactions-wrapper'>
  <FakeButton class='_desktopOnly' onClick={() => { shown = !shown }}>
    <IconSmiley weight='regular' />
  </FakeButton>
  {#if shown}
    <div
      class='emoji-picker'
      on:click_outside={() => { shown = false }}
      use:clickOutside
      transition:fly={{ duration: 150, y: -10 }}
    >
      <div class='emoji-list-desktop _desktopOnly'>
        {#each reactionEmojis as emoji (emoji)}
          <button
            type='button'
            on:click={() => selectEmoji(emoji)}
          >{emoji}</button>
        {/each}
      </div>
    </div>
  {/if}
</div>

<div class='emoji-list-mobile _mobileOnly'>
  {#each reactionEmojis as emoji (emoji)}
    <button
      type='button'
      on:click={() => selectEmoji(emoji)}
    >{emoji}</button>
  {/each}
</div>
<style lang='scss'>
  .reactions-wrapper {
    position: relative;
    display: flex;

    > :global(.fake-button):hover {
      color: var(--text-tertiary-color);
    }
  }

  .emoji-picker {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    min-width: 14rem;
  }

  button {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
  }

  .emoji-list-desktop {
    display: flex;
    flex-wrap: wrap;
    max-width: 19.2rem;
    padding: 0.8rem;
    background: var(--main-background);
    border: solid var(--Gray-Medium) 0.1rem;
    border-radius: 0.8rem;

    > button {
      margin: 0.4rem;
    }
  }

  @media(max-width: 768px) {
    .emoji-list-mobile {
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;
      padding: 0.8rem;

      > button {
        font-size: 2rem;
      }
    }
  }
</style>
