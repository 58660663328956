<script lang='ts'>
  // todo - we must remember last clicked input or textarea if we want to use keyboard everywhere
  // todo - additional keys on long press https://mottie.github.io/Keyboard/docs/altkeys-popup.html
  // todo - allow attaching same keyboard on multiple inputs
  // todo - design ideas from https://greywyvern.com/code/javascript/keyboard it looks very nice
  // todo - allow simulating keyboard with my current layout (typing on my keyboard will type on the screen with choosen layout)
  // todo - allow keys sequence for example for: Korean, Japanese, Chinese, etc.

  /*
 Zero-width characters
 U+200B ZWSP
 U+200C ZWNJ
 U+200D ZWJ
 U+200E LEFT-TO-RIGHT MARK
 U+200F RIGHT-TO-LEFT MARK
*/
  import { onDestroy, onMount, tick } from 'svelte'

  import { getIsoFromUrl } from '@/helpers/apiCall'
  import { _ } from '@/helpers/i18n'
  import { isoToLayout } from '@/helpers/keyboard/isoToLayout'
  import { allLayouts, LayoutNames } from '@/helpers/keyboard/layouts'
  import { notifier } from '@/helpers/notifier'

  import IconCaretDown from "@/components/icons/phosphor/IconCaretDown.svelte";
  import IconKeyboard from "@/components/icons/phosphor/IconKeyboard.svelte";

  import { KeyboardLayout, KeyboardVariant } from '@/definitions/langoid'
  import { lastFocusedInput } from '@/store'

  type KeyName = 'accept' | 'alt' | 'tab' | 'altGr' | 'bksp' | 'cancel' | 'empty' | 'enter' | 'lock' | 'shift' | 'space'

  export let iso = ''
  export let variant = 'normal'
  let element: HTMLInputElement | HTMLTextAreaElement

  let keyboard: any // keyboard layout
  let enabledVariant: KeyboardVariant = 'normal'
  let currentLang: any = iso
  let currentLayout = ''
  let keyboardCollapsed = false
  const setup: {
    last: string,
    lock: boolean,
    variant: KeyboardVariant
  } = {
    last: '',
    lock: false,
    variant: 'normal'
  }
  let availableLayouts = {} as Record<string, KeyboardLayout>

  const unsubscribe = lastFocusedInput.subscribe((value: HTMLInputElement | HTMLTextAreaElement | null) => {
    if (value !== null) {
      element = value
    }
  })

  const loadLayoutGroup = async (lang: LayoutNames, useLayout = '') => {
    if (!(lang in allLayouts)) {
      console.error('keyboard load layout group: unknown language', lang)
      return
    }

    const {
      layout,
      layouts
    } = allLayouts[lang]
    availableLayouts = layouts
    if (useLayout === '') {
      keyboard = parseKeyboard(layout)
    } else {
      keyboard = parseKeyboard(layouts[useLayout] ?? layout)
    }
  }

  $: if (iso) {
    const load = async () => {
      try {
        const file = isoToLayout(iso)
        await loadLayoutGroup(file as any)
      } catch (error) {
        console.error('keyboard load layout group:', error)
      }
    }
    load()
  }

  onMount(() => { // prevent native keyboard (need to check if this works)
    if (!iso) {
      iso = getIsoFromUrl()
      currentLang = isoToLayout(iso)
    }
    if (element) {
      element.setAttribute('readonly', 'readonly')
      element.addEventListener('click', () => {
        element.removeAttribute('readonly')
        element.focus()
      })
    }
  })

  const specialMap: Record<KeyName, string> = {
    accept: 'Ctrl',
    alt: 'Alt',
    altGr: 'AltGr',
    bksp: '⌫',
    cancel: 'Ctrl',
    empty: ' ',
    enter: '↵',
    lock: '⇪',
    shift: '⇧',
    space: '␣',
    tab: '⇥'
  }

  const parseKeyboard = (kbd: KeyboardLayout): any => {
    const k: any = {}
    const keys = Object.keys(kbd).filter(el => !['name', 'lang', 'rtl'].includes(el))
    for (const key of keys) {
      if (!['default', 'shift', 'alt', 'alt-shift', 'normal'].includes(key)) {
        continue
      }
      const key2: KeyboardVariant = key as unknown as KeyboardVariant
      k[key] = (kbd[key2] as string[]).map((el) => el.split(' ').map((el2) => {
        if (el2?.[0] === '{' && el2.length > 1) {
          let type: KeyName = el2.slice(1, -1) as KeyName
          /* eslint-disable sort-keys */
          const switches: Record<string, KeyName> = {
            t: 'tab',
            s: 'shift',
            n: 'enter',
            b: 'bksp',
            e: 'empty',
            c: 'cancel',
            a: 'accept'
          }
          /* eslint-enable sort-keys */
          if (type in switches) {
            type = switches[type]
          }
          return {
            content: specialMap[type] || type,
            group: 'special',
            type
          }
        } else {
          return {
            content: el2,
            group: 'normal'
          }
        }
      }))
    }
    return k
  }

  const onClick = (row: number, key: number, keyContentObject: any) => {
    if (!element) {
      notifier.error($_('mix.clickEditableField'))
      return
    }
    const { content, group } = keyContentObject
    let type = keyContentObject.type
    if (setup.last === 'altGr' || setup.last === 'alt-shift') {
      type = 'normal'
      enabledVariant = 'normal'
    }
    if (group === 'normal') {
      if (enabledVariant === 'shift' && !setup.lock) {
        enabledVariant = 'normal'
      }
      element.value += content
      focusLastElement()
      setup.last = content
    } else {
      if (type === 'shift' || type === 'lock') {
        handleShiftAndCaps(type)
      } else if (type === 'altGr') {
        if (setup.last === 'shift') {
          // for alt ant alt-shift we first check if variant exist in the current layout
          if (keyboard['alt-shift']) enabledVariant = 'alt-shift'
        } else {
          if (keyboard.alt) enabledVariant = 'alt'
        }
      } else if (type === 'bksp') {
        element.value = element.value.slice(0, -1)
        element.focus()
      } else if (type === 'space') {
        element.value += ' '
        element.focus()
      } else if (type === 'tab') {
        element.value += '\t'
        element.focus()
      } else if (type === 'enter') {
        element.value += '\n'
      }

      setup.last = type
    }
  }

  function handleShiftAndCaps (type: 'shift' | 'lock') {
    if (type === 'shift') {
      if ((setup.last === 'shift' || setup.last === 'lock') && enabledVariant !== 'normal') {
        enabledVariant = 'normal'
        setup.lock = false
      } else {
        enabledVariant = 'shift'
      }
    } else if (type === 'lock') {
      if (setup.lock) {
        enabledVariant = 'normal'
        setup.lock = false
      } else {
        enabledVariant = 'shift'
        setup.lock = true
      }
    }
    setup.last = type
  }

  document.addEventListener('keydown', (event) => {
    const { code } = event
    if (code === 'ShiftLeft' || code === 'ShiftRight') {
      handleShiftAndCaps('shift')
    }
  })

  const languages = [
    'albanian', 'arabic', 'armenian', 'assamese', 'azeri', 'baluchi', 'bashkir', 'belarusian', 'belgian', 'bengali', 'brazilian', 'bulgarian', 'burmese', 'canadian', 'catalan', 'chinese', 'czech', 'danish', 'deutchland', 'devanagari', 'divehi', 'dutch', 'english', 'estonian', 'ethiopic', 'faeroese', 'farsi', 'finnish', 'french', 'gaelic', 'georgian', 'gilaki', 'greek', 'greenlandic', 'gujarati', 'hausa', 'hebrew', 'hindi', 'hungarian', 'hungarianansi', 'icelandic', 'igbo', 'inuktitut', 'irish', 'italian', 'japanese', 'kannada', 'kazakh', 'korean', 'kurdish', 'kyrgyz', 'lao', 'latvian', 'lithuanian', 'luxembourgish', 'macedonian', 'malayalam', 'maltese', 'maori', 'marathi', 'mongolian', 'nepali', 'nigerian', 'nko', 'norwegian', 'odia', 'oriya', 'pashto', 'persian', 'polish', 'portuguese', 'punjabi', 'romanian', 'russian', 'russianOld', 'sami', 'serbian', 'setswana', 'sindhi', 'sinhala', 'slovak', 'slovenian', 'spanish', 'swedish', 'swedish-Sami', 'swiss-German', 'syriac', 'tajik', 'tamil', 'tatar', 'telugu', 'thai', 'tibetan', 'turkish', 'turkmen', 'ukrainian', 'urdu', 'urduStandard', 'uyghur', 'uzbek', 'vietnamese', 'wolof', 'yakut', 'yoruba'
  ]
  const changeLanguage = async () => {
    await tick()
    if (currentLang) {
      loadLayoutGroup(currentLang, currentLayout)
    }
  }

  const focusLastElement = () => {
    if (element !== null && document.activeElement !== element) {
      element.focus()
    }
  }

  const toggleKeyboard = () => {
    keyboardCollapsed = !keyboardCollapsed
  }

  onDestroy(() => {unsubscribe()})
</script>

<div class='keyboard-page' class:-collapsed={keyboardCollapsed}>
  {#key currentLang + ':' + enabledVariant}
    {#if keyboard}
      <div class='keyboard-controls' class:-collapsed={keyboardCollapsed}>
        <div class='right-menus' class:-collapsed={keyboardCollapsed}>
          <select on:change={changeLanguage} bind:value={currentLang} name=''>
            <option value=''>Choose language</option>
            {#each languages as language}
              <option value={language}>{language}</option>
            {/each}
          </select>
          <select
            class='layout-chooser'
            class:-visible={Object.keys(availableLayouts)?.length > 1}
            on:change={changeLanguage}
            bind:value={currentLayout}
            name=''
          >
            <option value=''>Choose layout</option>
            {#each Object.keys(availableLayouts) as availableLayout}
              <option value={availableLayout}>{availableLayout}</option>
            {/each}
          </select>
        </div>
        {#if variant !== 'chat'}
          <button
            class='_tertiary-btn'
            type='button'
            on:click={toggleKeyboard}
          >
            {#if keyboardCollapsed}
              <IconKeyboard />
            {:else}
              <IconCaretDown size='16' weight='bold' />
            {/if}
          </button>
        {/if}
      </div>
      <div class='full-keyboard' class:-collapsed={keyboardCollapsed}>
        {#if keyboard[enabledVariant]}
          {#each keyboard[enabledVariant] as rowContent, row}
            <div class='keyboard-row -row{row}'>
              {#if typeof rowContent === 'object' && rowContent !== null && rowContent.length}
                {#each rowContent as keyContentObject, key}
                  {@const keyContent = keyContentObject.content}
                  <span
                    class={`key -${keyContentObject.group === 'special' ? keyContentObject.type : 'normal'}`}
                    role='button'
                    tabindex='0'
                    on:click|preventDefault={() => onClick(row, key, keyContentObject)}
                    on:keypress={() => {}}
                  >
                    {keyContent}
                  </span>
                {/each}
              {/if}
            </div>
          {/each}
        {/if}
      </div>
    {/if}
  {/key}
</div>

<style lang='scss'>
  .layout-chooser {
    visibility: hidden;

    &.-visible {
      visibility: visible;
    }
  }

  .keyboard-page {
    position: relative;
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;
    padding: 1.2rem;
    background-color: var(--Gray-Lighter);
    box-shadow: var(--Shadow-300);
    user-select: none;

    &.-tools {
      background: var(--Error-Medium);
    }

    &.-collapsed {
      position: absolute;
      right: 0;
      bottom: 4rem;
      left: auto;
      overflow: hidden;
      width: auto;
      padding: 0;
      background: none;
    }
  }

  .keyboard-controls {
    display: flex;
    justify-content: space-between;

    &.-collapsed {
      display: block;
    }
  }

  .right-menus {
    display: flex;
    gap: 0.4rem;

    &.-collapsed > select {
      display: none;
    }
  }

  .full-keyboard.-collapsed {
    display: none
  }

  .full-keyboard-button {
    position: absolute;
    top: -2.5rem;
    right: 0;
    padding: 0.4rem;
    font-size: 1.2rem;
    color: var(--inverted-text-color);
    border-radius: 0.4rem 0 0 0.4rem;
    cursor: pointer;

    &:hover {
      color: var(--text-primary-color);
      background: var(--Gray-Medium);
    }

    > .keyboardChecked {
      display: none;
    }
  }

  .keyboard-row {
    display: flex;
    align-content: space-between;
    align-items: center;
    justify-content: space-between;

    &.-row1 {
      padding: 0 1rem;
    }

    > .key {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      min-width: 2rem;
      height: 4rem;
      margin: 0.4rem;
      padding: 0.1rem;
      font-size: 1.6rem;
      line-height: 1;
      text-align: center;
      color: var(--text-secondary-color);
      background: var(--main-background);
      border-radius: 0.4rem;
      box-shadow: 0 0.949px 1.899px 0 rgba(16, 24, 40, 0.06), 0 0.949px 2.848px 0 rgba(16, 24, 40, 0.10);
      cursor: pointer;

      &.-accept,
      &.-cancel,
      &.-control,
      &.-alt,
      &.-altGr {
        flex-grow: 2;
      }

      &.-space {
        flex-grow: 8;
        width: 50%;
      }

      &:hover {
        box-shadow: none;
      }

      &.-shift,
      &.-capslock {
        margin: 0.4rem;
        padding: 0.4rem;
        font-weight: bold;
        text-transform: lowercase;
        cursor: pointer;
      }

      &.-shift {
        text-transform: none;
      }

      &.-capslock {
        text-transform: uppercase;
        color: var(--inverted-text-color);
        background: var(--Error-Medium);
      }
    }
  }

  @media (max-width: 768px) {
    .keyboard-row > .key {
      margin: 0.1rem;
      font-size: 1.4rem;
    }
  }

  @media (max-width: 414px) {
    .keyboard-row > .key {
      font-size: 1.2rem;
    }
  }
</style>
