<script lang='ts'>
  import { resizeTextarea } from '@/helpers/actions/resizeTextarea'
  import { _ } from '@/helpers/i18n'

  import IconQuestion from "@/components/icons/phosphor/IconQuestion.svelte";
  import Overlay from '@/components/ui/Overlay.svelte'

  import { ChatAnswer, ChatMessage, ChatQuestion } from '@/definitions/langoid'

  export let msg: ChatMessage,
    isMyMsg: boolean,
    send = (obj: unknown, type: string) => {
    },
    question: ChatQuestion[],
    answer: ChatAnswer

  const canAnswer = isMyMsg && !answer
  let showExplanationForm = false
  let explanationAnswer = ''
  let explanationWords: ChatQuestion[] = []

  const sendExplanation = () => {
    send(
      {
        explanation: explanationAnswer,
        messageId: msg.id,
        receiver: msg.receiver,
        sender: msg.sender,
        words: explanationWords
      },
      'explanationAnswer'
    )

    showExplanationForm = false
  }

  const openExplanationForm = () => {
    explanationWords = []
    question.forEach((i: ChatQuestion) => {
      explanationWords.push({
        ...i,
        editedWord: ''
      })
    })
    showExplanationForm = true
  }
</script>

<div class='explanation-box' class:-right={isMyMsg}>
  {#if question}
    <div
      class='explanation-question'
      class:-clickable={canAnswer}
      role='button'
      tabindex='0'
      on:keypress={() => {}}
      on:click={canAnswer ? openExplanationForm : undefined}
    >
      <span class='infoTitle'> <IconQuestion size='16' weight='fill' />{$_('chat.explanationRequested')}</span>
      <div class='message'>
        {#if question.length > 0}
          {#each question as item}
            <span class='word' class:-checked={item.checked}>{item.word} </span>
          {/each}
        {:else}
          <span>{$_('chat.knowButDontUndarstand')}</span>
        {/if}
      </div>
    </div>
  {/if}
  {#if answer}
    <div class='explanation-answer'>
      <span class='infoTitle'> <IconQuestion size='16' weight='fill' />{$_('chat.explanationAnswer')}</span>
      {#if answer.words.length > 0}
        <div class='message'>
          {#each answer.words as w}
            <span>
              {w.word}
              {#if w.editedWord}
                <span class='edited-word'>{w.editedWord}</span>
              {/if}
            </span>
          {/each}
        </div>
      {/if}
      {#if answer.explanation}
        <div class='explanation'>{answer.explanation}</div>
      {/if}
    </div>
  {/if}
</div>

{#if showExplanationForm}
  <Overlay onClick={() => (showExplanationForm = false)}>
    <div class='modal-container'>
      <form class='explanation-form' on:submit|preventDefault={sendExplanation}>
        <div class='inputs-wrapper'>
          {#each question as item, index}
            <span>
              {#if item.checked}
                <input class='textInput' type='text' bind:value={explanationWords[index].editedWord} />
              {/if}
              {item.word}
            </span>
          {/each}
        </div>
        <p class='label'>{$_('chat.explanation')}</p>
        <textarea cols='30' rows='5' bind:value={explanationAnswer} use:resizeTextarea />
        <input class='submitButton' type='submit' value={$_('mix.send')} />
      </form>
    </div>
  </Overlay>
{/if}

<style lang='scss'>
  .explanation-box {
    clear: both;
    max-width: 56rem;
    margin-bottom: 3.2rem;
  }

  .explanation-question, .explanation-answer {
    > .infoTitle {
      display: flex;
      align-items: center;
      margin-bottom: 0.8rem;
      font: var(--Medium-200);
    }
  }

  .explanation-question {
    padding: 0.8rem 1.6rem;
    font: var(--Regular-400);
    background-color: var(--warning-background);
    border-right: 0.2rem solid var(--warning-text);
    border-left: 0.2rem solid var(--warning-text);

    > .infoTitle {
      color: var(--warning-text);
    }

    &.-clickable {
      padding: 0.8rem;
      cursor: pointer;

      &:hover {
        background: var(--Warning-Light);
      }
    }

    > .message {
      > .word {
        margin: 0 0.1rem;
        padding: 0 0.2rem;
        line-height: 2;

        &.-checked {
          padding: 0.4rem 0.8rem;
          color: var(--warning-background);
          background-color: var(--warning-text);
          border-radius: 0.8rem;
        }
      }
    }
  }

  .explanation-answer {
    margin-top: 2.4rem;
    padding: 0.8rem 1.6rem;
    font: var(--Regular-400);
    background-color: var(--success-background);
    border-right: 0.2rem solid var(--success-text);
    border-left: 0.2rem solid var(--success-text);

    > .infoTitle {
      color: var(--success-text);
    }

    > .message {
      margin-bottom: 1.2rem;
    }

    > .explanation {
      padding: 0.6rem 1.2rem;
      background: var(--main-background);
      border: 0.1rem solid var(--Gray-Light);
      border-radius: 0.8rem;
    }
  }

  .edited-word {
    display: inline-block;
    padding: 0.4rem 0.8rem;
    color: var(--success-background);
    background-color: var(--success-text);
    border-radius: 0.8rem;
  }

  .modal-container {
    max-width: 100%;
    padding: 2.4rem;
    background-color: var(--main-background);
  }

  .explanation-form {
    > .submitButton {
      display: block;
      margin-top: 2rem;
    }
  }

  .inputs-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 0;

    > span {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding: 0 0.4rem;

      > .textInput {
        width: 9.6rem;
        margin: 0;
      }
    }
  }
</style>
